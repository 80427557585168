import React from "react"
import * as Report from "./src/context/report.context"

export const wrapRootElement = ({ element }) => (
  <Report.Provider>{element}</Report.Provider>
)

export const onRouteUpdate = ({location}) => {
  anchorScroll(location);
  return true;
};

export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition
}) => {
  anchorScroll(location);
  return true;
}

function anchorScroll(location){
  // Check for location so build does not fail
  if (location && location.hash) {
    setTimeout(() => {
      // document.querySelector(`${location.hash}`).scrollIntoView({ behavior: 'smooth', block: 'start' });
      const item = document.querySelector(`${location.hash}`).offsetTop;
      window.scrollTo({top: item, left: 0, behavior: 'smooth'});
    }, 0);
  }
}